.root {
  max-width: 100%;
  max-height: 100%;
}
.media {
  height: 0;
  padding-top: 56.25%; // 16:9
}
.cardActions {
  display: flex;
  justify-content: flex-end;
}
.cardContent {
  display: flex;
  justify-content: space-between;
  color: #20b2aa;
}
