/* General Styles */
.modal-image {
  max-width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 1000;
}

.modal-content {
  background: ghostwhite;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: auto;
  height: auto;
  min-width: 300px;
  min-height: 200px;
  max-width: 90%;
  max-height: 90%;
  box-sizing: border-box;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}

.modal-inner p {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  text-align: left;
}

.outter-modal-div {
  margin: 30px;
  padding: 30px;
  border: 1px solid rgba(232, 230, 230, 0.75);
}

/* Navigation Styles */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 30px 0;
  width: 100%;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  font-weight: bold;
  text-decoration: none;
  color: #545b5f;
  text-transform: capitalize;
  padding: 10px;
}

.nav-link-all {
  position: relative;
}

.nav-link:hover {
  color: lightseagreen;
}

/* Avatar Styles */
.write-avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

/* Article Styles */
.about-article {
  display: flex;
  justify-content: flex-start;
}

.about-article img {
  margin-right: 10px;
}

.article-details {
  display: flex;
  position: relative;
  bottom: 8px;
}

.article-details p {
  font-weight: bold;
  padding-right: 10px;
  font-size: 12px;
  text-align: left;
}

.article-details p:nth-child(1) {
  text-decoration: overline;
  text-decoration-color: aqua;
}

.article-details p:nth-child(3) {
  text-decoration: underline;
  text-decoration-color: aqua;
}

.line {
  border: 1px solid rgba(232, 230, 230, 0.75);
}

.category-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 50px;
  margin-top: 20px;
}

/* Link Container Styles */
.link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.left-link,
.right-link {
  text-decoration: none;
  color: inherit;
  padding: 10px;
  font-weight: bolder;
}

.right-link:hover {
  color: lightseagreen;
}

/* Socials Styles */
.socials-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.socials {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.socials a:hover {
  color: darkred;
}

.social-media {
  width: 24px;
  height: 24px;
  fill: #000;
}

/* Current Category Styles */
.current-category {
  text-align: center;
  font-size: 16px;
}

.current-category a {
  text-decoration: none;
  color: #000;
}

.current-category a:hover {
  color: lightseagreen;
}

/* Flex Item Styles */
.flex-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.modify-post {
  color: gray;
  margin: 0 12px;
}

.modify-post:hover {
  background-color: red;
  padding: 10px;
  color: #fff;
  border-radius: 20px;
}

.edit-post {
  margin-right: 0;
}

/* Responsive Styling */
@media only screen and (max-width: 800px) {
  .category-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .modal-image {
    height: auto;
  }

  .modal-content {
    padding: 15px;
  }

  .close-button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .modal-content {
    min-width: 90%;
    min-height: auto;
  }

  .modal-image {
    height: auto;
  }

  .close-button {
    font-size: 14px;
  }

  .outter-modal-div {
    margin: 15px;
    padding: 15px;
  }

  .nav-container {
    flex-direction: column;
    align-items: center;
    .nav-link-all {
      margin: 0;
    }
    .editdelete {
      background-color: lightseagreen;
      .edit-post {
        color: #000;
      }
      .delete-post {
        color: darkred;
      }
      justify-content: center;
    }
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .about-article {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-article img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .article-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .article-details p {
    padding-right: 0;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .link-container {
    flex-direction: column;
    align-items: center;
  }

  .left-link,
  .right-link {
    margin: 5px 0;
  }
}

@media (min-width: 768px) {
  .socials-div {
    flex-direction: row;
    justify-content: space-between;
  }

  .socials {
    margin-bottom: 0;
  }

  .current-category {
    font-size: 18px;
  }
}
