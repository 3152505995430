.qr-icon-container {
  min-width: 230px;
  // width: 2rem;
  height: 42px;
  background-color: darkred;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative; /* Needed for item-count positioning */

  .shopping-icon {
    width: 24px;
    height: 24px;
    color: ghostwhite;
  }

  .item-count {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    bottom: 12px;
  }
}

@media screen and (max-width: 768px) {
  .qr-icon-container {
    min-width: 180px;
    padding: 0 25px;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
  }
}

@media screen and (max-width: 480px) {
  .qr-icon-container {
    min-width: 150px;
    padding: 0 20px;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
  }
}
