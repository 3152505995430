// html,

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
