.downdropselection {
  margin-bottom: 30px;
  h2 {
    margin-top: 10px;
    text-align: center;
    color: gray;
    flex-shrink: 1;
    font-size: 2rem;
    align-self: center;
    margin: 0 auto;
  }

  #categoryDropdown {
    width: 90%;
    height: 40px;
    border: 1px solid lightseagreen;
  }
}

label {
  margin-right: 10px;
}

select {
  padding: 5px;
  border-radius: 5px;
}
