@font-face {
  font-family: beezle;
  src: url('../../assets/fonts/Beezle/Beezle.otf');
}

.big-image {
  height: 65vh;
  position: relative;
  top: -20px;
  background-size: contain;
  background-image: url('../../assets/afro-14.png');
  background-color: #efede7;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    // left: 13vw;

    .bannerTitle {
      margin-top: 20px;
    }

    span {
      font-weight: bold;
      // opacity: 0.8;
      color: #6b7280;
    }

    h1 {
      letter-spacing: 0px;
      color: #1f0f19;
      font: normal 10vw 'Open Sans', cursive;
      font-family: beezle;
      margin: 10px 0;
    }

    p {
      margin: 0;
      color: lightseagreen;
    }
  }
}
@media screen and (max-width: 1024px) {
  #vids {
    margin: 50px;

    h3 {
      font-size: 1.8em;
    }

    .arrow {
      font-size: 28px;
      padding-top: 10px;
    }
  }
  .overlay {
    background: whitesmoke;
    opacity: 0.8;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .big-image {
    height: 60vw;
    top: -10px;

    .overlay {
      background: whitesmoke;
      opacity: 0.8;
      span {
        color: ghostwhite;
        background: gray;
        border-radius: 5px;
      }

      h1 {
        font-size: 12vw;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .big-image {
    height: 70vw;
    top: 0;

    .overlay {
      background: whitesmoke;
      opacity: 0.8;
      span {
        display: none;
      }

      h1 {
        font-size: 12vw;
      }
    }
  }
}

#vids {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 20px;

  h3 {
    text-align: center;
    color: gray;
    font-weight: bolder;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 20%;
    margin-top: 10px;

    .arrow {
      margin-left: 12px;
      font-size: 30px;
      padding-top: 12px;
      color: darkred;
    }
  }
}

@media screen and (max-width: 768px) {
  #vids {
    margin: 40px;

    h3 {
      font-size: 1.5em;
    }

    .arrow {
      font-size: 25px;
      padding-top: 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  #vids {
    margin: 20px;

    h3 {
      font-size: 1.2em;
    }

    .arrow {
      font-size: 20px;
      padding-top: 8px;
    }
  }
}
