// .cart-dropdown-container {
//   position: absolute;
//   width: 90%; /* Responsive width */
//   max-width: 240px; /* Limit maximum width */
//   max-height: 80vh; /* Limit maximum height */
//   display: flex;
//   flex-direction: column;
//   padding: 20px;
//   border: 1px solid ghostwhite;
//   border-radius: 20px;
//   background-color: ghostwhite;
//   top: 101%;
//   right: 1%;
//   z-index: 5;
//   overflow: hidden; /* Hide overflow content */

//   .empty-message {
//     font-size: 18px;
//     margin: 20px auto; /* Reduce margin */
//   }

//   .cart-items {
//     flex: 1; /* Allow items to expand */
//     display: flex;
//     flex-direction: column;
//     overflow-y: auto; /* Scroll only vertically */
//   }

//   button {
//     margin-top: 10px; /* Add some space */
//     align-self: center; /* Center the button */
//   }
// }

// .store-badges {
//   display: flex;
//   align-items: center;
//   justify-content: center; /* Center badges horizontally */
//   margin-top: 20px; /* Add some space */
// }

// .store-badges .store-badge {
//   width: 60px;
//   height: 60px;
// }

.cart-dropdown-overlay {
  position: fixed; /* Use fixed positioning for overlay */
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire screen */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center the cart dropdown */
  align-items: center; /* Center the cart dropdown */
  z-index: 1000; /* Ensure it's above other content */
}

.cart-dropdown-container {
  width: 90%; /* Responsive width */
  max-width: 400px; /* Adjust maximum width */
  max-height: 80vh; /* Limit maximum height */
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ghostwhite;
  border-radius: 20px;
  background-color: ghostwhite;
  overflow: hidden; /* Hide overflow content */

  .empty-message {
    font-size: 18px;
    margin: 20px auto; /* Reduce margin */
  }

  .cart-items {
    flex: 1; /* Allow items to expand */
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Scroll only vertically */
  }

  button {
    margin-top: 10px; /* Add some space */
    align-self: center; /* Center the button */
  }

  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; // Adjust as needed

    img {
      width: 80%; // Adjust the width of the image
      max-width: 200px; // Set a maximum width if needed
    }

    .app-label {
      margin-top: 10px; // Adjust as needed
      font-size: 18px; // Adjust the font size
      color: #333; // Adjust the color
    }
  }
}

.store-badges {
  display: flex;
  align-items: center;
  justify-content: center; /* Center badges horizontally */
  margin-top: 20px; /* Add some space */
}

.store-badges .store-badge {
  width: 60px;
  height: 60px;
}

@media (max-width: 768px) {
  .cart-dropdown-container {
    max-width: 90%;
    padding: 15px;

    .empty-message {
      font-size: 16px;
      margin: 15px auto;
    }

    button {
      margin-top: 8px;
    }
  }
}

@media (max-width: 480px) {
  .cart-dropdown-container {
    max-width: 100%;
    padding: 10px;

    .empty-message {
      font-size: 14px;
      margin: 10px auto;
    }

    button {
      margin-top: 5px;
    }
  }
}
