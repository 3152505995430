.directory-item-container {
  min-width: 100%;
  height: 440px;
  flex: 1 1 auto;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  // border: 1px solid black;
  margin: 150px 7.5px 15px;
  overflow: hidden;
  p {
    // font-weight: lighter;
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .body {
      opacity: 0.9;
    }
  }

  &.large {
    height: 380px;
  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
  }
  .wholeimg {
    background-size: cover;
  }
  .body {
    height: 90px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: lightseagreen;
    // border-radius: 12px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: 0.7;
    position: absolute;
    color: #fff;

    h2 {
      font-weight: bold;
      margin: 0 6px 0;
      font-size: 22px;
      color: #fff;
    }
    // h2:hover {
    //   color: #febebd;
    // }

    p {
      font-weight: lighter;
      font-size: 16px;
    }
  }
  .body:hover {
    background-color: #fff;
    color: lightseagreen;
    h2 {
      color: lightseagreen;
    }
  }
}
