// .authentication-container {
//   display: flex;
//   width: 900px;
//   justify-content: space-between;
//   margin: 30px auto;
// }

.authentication-container {
  display: flex;
  flex-direction: column; /* Initially stack the forms vertically */
  max-width: 900px; /* Limit the width to 900px */
  margin: 30px auto;
}

/* Media query for screens smaller than 900px */
@media screen and (max-width: 900px) {
  .authentication-container {
    flex-direction: column; /* Stack the forms vertically */
  }
}

/* Media query for screens larger than 900px */
@media screen and (min-width: 901px) {
  .authentication-container {
    flex-direction: row; /* Arrange the forms side by side */
    justify-content: space-between;
  }
}
