$form-background-color: #fffefe;
$scarlet: #800317;
$mildBlack: #1f0f19;

.form-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  font-size: 1.875rem;
  font-weight: 500;
  color: #333;
  margin-top: 8px;
}

.list-input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  color: #333;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 0.625rem;
  height: 3rem;
  border-color: $scarlet;

  &:focus {
    border-color: $scarlet;
    outline: none;
  }
}

.error {
  color: $scarlet;
  text-align: center;
}

.submit {
  background-color: lightseagreen;
  text-align: center;
  color: #fff;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: darken($scarlet, 10%);
  }

  &:focus {
    outline: none;
  }
}

.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  height: 80%;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .alert-content {
    width: 95%;
    padding: 15px;
  }
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 320px) {
  .mySwiper .swiper-slide img {
    width: 100%;
  }
}

@media (min-width: 640px) {
  .mySwiper .swiper-slide img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .mySwiper .swiper-slide img {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .mySwiper .swiper-slide img {
    width: 100%;
  }
}

/* Blinking and Color Changing Styles */
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes colorChange {
  0% {
    color: lightslategray;
  }
  33% {
    color: #c0de0f;
  }
  66% {
    color: lightseagreen;
  }
  100% {
    color: lightsalmon;
  }
}

.blinking-text {
  animation: blink 1s step-start infinite, colorChange 50s infinite;
}
.blinking-text-longer {
  animation: blink 3s step-start infinite, colorChange 120s infinite;
}
.list-section-div {
  position: relative;
  bottom: 3em;
}
