// .navigation {
//   height: 70px;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 25px;
//   position: relative;
// }

// .container {
//   display: flex;
//   align-items: center;
//   margin: 0 17px;
// }

// .logo-container {
//   display: flex;
//   align-items: center;
// }

// .logo-hover-container {
//   position: relative;
//   display: inline-block;
// }

// .logo-hover-container:hover img {
//   content: url('../../assets/hairpick1-outline.png');
// }

// .logo {
//   height: 62px;
//   width: auto;
// }

// .user-info {
//   display: flex;
//   align-items: center;
//   margin-right: 20px;
// }

// .user {
//   height: 40px;
//   width: auto;
//   margin-right: 10px;
// }

// .nav-links-container {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin: 0 17px;
//   flex-grow: 1;
// }

// .nav-link {
//   padding: 10px 15px;
//   cursor: pointer;
//   color: lightseagreen;
// }

// .story {
//   position: relative;
//   bottom: 7px;
// }

// .centered-image {
//   position: absolute;
//   top: 50%; /* Adjust to position vertically */
//   left: 50%;
//   transform: translate(-50%, -50%);
//   height: 90px; /* Adjust height as needed */
// }

// @media screen and (max-width: 768px) {
//   .navigation {
//     flex-direction: row;
//     height: auto;
//   }

//   .container {
//     display: flow;
//   }

//   .nav-links-container {
//     margin: 0;
//     flex-grow: 0;
//   }

//   .logo {
//     height: 50px; /* Adjust height for smaller screens */
//   }

//   .nav-links-container {
//     justify-content: center; /* Center links */
//     flex-wrap: wrap; /* Wrap links to multiple lines if necessary */
//     margin: 10px 0;

//     .nav-link {
//       padding: 8px 12px;
//     }
//   }

//   .user-info {
//     margin: 10px 0; /* Adjust margin for better spacing */
//   }

//   .centered-image {
//     top: 42%;
//     left: 28%;
//   }
// }

// @media screen and (max-width: 480px) {
//   .navigation {
//     padding: 0 5px;
//   }

//   .logo {
//     height: 40px; /* Adjust height for smaller screens */
//   }

//   .nav-links-container {
//     flex-direction: column; /* Stack links vertically */
//     align-items: center;

//     .nav-link {
//       padding: 6px 10px;
//     }
//   }

//   .user-info {
//     flex-direction: column;
//     margin: 10px 0;

//     .user {
//       margin-right: 0;
//       margin-bottom: 5px; /* Adjust margin for better spacing */
//     }
//   }

//   .centered-image {
//     top: 42%;
//     left: 41%;
//   }
// }

// .away {
//   border-top-right-radius: 20px;
//   border-bottom-left-radius: 20px;
//   background: #efede7;
// }

// .banner {
//   background-color: darkred;
//   color: ghostwhite;
//   text-align: center;
//   font-weight: bold;
//   position: relative;
//   padding: 1px 0;
//   width: 100%;
//   box-sizing: border-box;
//   border-top-right-radius: 20px;
//   border-bottom-left-radius: 20px;
// }

// .banner p {
//   font-family: monospace;
//   letter-spacing: 0.5px;
// }

// .banner {
//   position: relative;
//   text-align: center;
//   font-weight: bold;
//   padding: 10px 0; /* Adjusted padding for better visual */
//   width: 100%;
//   box-sizing: border-box;
//   border-top-right-radius: 20px;
//   border-bottom-left-radius: 20px;
//   overflow: hidden; /* Ensure pseudo-element does not overflow the container */
//   color: ghostwhite;
// }

// .banner::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   opacity: 0.7;
//   z-index: -1;
// }

// .banner p {
//   font-family: monospace;
//   letter-spacing: 0.5px;
//   position: relative; /* Ensure the text is above the pseudo-element */
//   z-index: 1;
// }

// @media (max-width: 768px) {
//   .banner {
//     font-size: 16px;
//     padding: 10px 15px;
//   }
// }

// @media (max-width: 480px) {
//   .banner {
//     font-size: 14px;
//     padding: 8px 10px;
//   }
// }

.banner {
  padding: 1rem;
  text-align: center;
  background-color: darkred;
  color: ghostwhite;
  text-align: center;
  font-weight: bold;
  position: relative;
  padding: 1px 0;
  width: 100%;
  box-sizing: border-box;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.banner p {
  font-family: monospace;
  letter-spacing: 0.5px;
}

.banner {
  position: relative;
  text-align: center;
  font-weight: bold;
  padding: 10px 0; /* Adjusted padding for better visual */
  width: 100%;
  box-sizing: border-box;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden; /* Ensure pseudo-element does not overflow the container */
  color: ghostwhite;
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  z-index: -1;
}

.banner p {
  font-family: monospace;
  letter-spacing: 0.5px;
  position: relative; /* Ensure the text is above the pseudo-element */
  z-index: 1;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  // width: 100%;
  padding: 0 1rem;
}

.user-info {
  display: flex;
  align-items: center;
}

.logo-container {
  margin: 0 auto;
}
.logo {
  width: 5rem;
}

.centered-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 1rem 0;
}

.device-labels {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.nav-links-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.nav-link {
  text-align: center;
  font-weight: 'bold';
}

.highlighted {
  color: darkred;
  font-size: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .navigation {
    flex-direction: row;
    justify-content: space-between;
  }

  .centered-images {
    flex-direction: row;
    justify-content: center;
  }

  .device-labels {
    justify-content: flex-start;
  }

  .nav-links-container {
    justify-content: flex-end;
  }
}
/* Base Styles */
.centered-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 1rem 0;
}

.centered-image {
  max-width: 100%;
  height: auto;
  margin: 0.5rem 0;
}
.story {
  position: relative;
  bottom: 7px;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .centered-images {
    flex-direction: row;
    justify-content: center;
  }

  .centered-image {
    max-width: 50%;
    margin: 0 1rem;
  }
}
