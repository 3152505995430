$egyptian: '../../assets/egyptian-grain.jpg';

.content {
  flex-grow: 1;
  background-color: #fafafa; /* Use 'inherit' to match the theme's default background color */
  // background-image: url('#{$egyptian}');
  background-size: cover;
  padding: 24px;
  border-radius: 20px;
  &:hover {
    background-color: darkred;
    // opacity: 0.8;
  }
}
