.category-container {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 50px;
  @media only screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 38px;
  background: lightseagreen;
  height: 50px;
  margin-bottom: 25px;
}

.category-title h2 {
  text-align: center;
  color: antiquewhite;
  flex-shrink: 1;
  font-size: 2vw; /* Use viewport width for a responsive font size */
  align-self: center;
  margin: 0 auto;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .category-title h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .category-title h2 {
    font-size: 1.2rem;
  }
}

.add-icon {
  width: 2.1em;
  height: 2.1em;
  background-color: #34cdb8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid antiqueghostwhite;
  cursor: pointer;
}

.modal-content {
  background: ghostwhite;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 70%;
  height: 90%;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.modal-textarea {
  height: 28em;
  margin-bottom: 20px;
  // background-color: chartreuse;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #800317;
  color: ghostwhite;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
}

.modal-submit-button {
  margin: 7em 0px;
  padding: 10px 20px;
  background: #2e7379;
  color: ghostwhite;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

////////
/// dropdown
///
///

.dropdown {
  background-color: lightseagreen;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: darkred;
  color: #fff;
}

//cover image
.cover-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  // height: 100vh; /* Adjust as needed */
}
.file-input-with-preview {
  display: flex;
  align-items: center;
  gap: 30px;
}

.file-input-with-preview input[type='file'] {
  flex: 1;
  margin: 0 auto;
  justify-self: center;
}
.image-preview {
  max-height: 18em;
  object-fit: cover;
}
.image-title {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
}
.right-link-all {
  font-size: 10px;
  font-weight: 300;
  position: relative;
  left: 50px;
}
