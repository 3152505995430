.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.join-list-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
}

.join-list-logo .logo {
  max-width: 100%;
  height: auto;
}

.form-container {
  flex: 1;
}
.label {
  color: gray;
  font-family: 'Quicksand', sans-serif;
}

.cube_body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 80px;
  /* margin-top: 60px; */
  margin-top: 5em;
}

.text {
  font-weight: bold;
  text-transform: uppercase;
}

.cube_container {
  perspective: 600px;
}

.cube {
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  animation: spin 60s linear infinite;
}

/* .cube_container {
  perspective: 900px;
}

.cube {
  position: relative;
  width: 500px;
  height: 500px;
  transform-style: preserve-3d;
  animation: spin 60s linear infinite;
} */

.cube__face {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  color: white;
  font-size: 24px;
  text-align: center;
  background-color: #800317;
  box-shadow: inset 0 0 100px #02203c;
  /* outline: 1px solid #02203c; */
}

.cube-face--left {
  transform: rotateY(-90deg) translateZ(150px);
}
.cube-face--front {
  transform: rotateY(0deg) translateZ(150px);
}
.cube-face--back {
  transform: rotateY(180deg) translateZ(150px);
}
.cube-face--right {
  transform: rotateY(90deg) translateZ(150px);
}
.cube-face--top {
  transform: rotateX(90deg) translateZ(150px);
}
.cube-face--bottom {
  transform: rotateX(-90deg) translateZ(150px);
}

.shadow {
  outline: none;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 0 100px #000;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

header {
  background-color: #000;
  color: #fff;
  padding-bottom: 20px;
}

.menu-items {
  list-style: none;
  padding: 0;
}

.menu-items li {
  font-size: 18px;
  color: black;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu-items li:hover {
  color: #800317;
}
.jumbotron {
  width: 100%;
  height: 60vh;
  position: relative;
}

.slider-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.slider-item h1 {
  font-size: 36px;
}
.custom-button {
  border-radius: 20px;
  border-color: #02203c;
}

.custom-button:hover {
  background-color: #800317;
  border-color: #800317;
}

.list-input {
  width: 20rem;
  height: 2rem;
  border-radius: 10px;
  border-color: red;
}
.submit {
  width: 20rem;
  height: 2.5rem;
  border-radius: 20px;
}
.form-divs {
  margin-right: 10px;
  margin-bottom: 30px;
}
.form {
  margin-top: 90px;
}
