.herbs-and-tea-container {
  // background: lightseagreen;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .steam {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #febebd;
    margin-top: -75px;
    margin-left: 75px;
    z-index: 0;
    opacity: 0.5;
  }

  #steam1 {
    -webkit-animation: steam1 4s ease-out infinite;
    animation: steam1 4s ease-out infinite;
  }

  #steam3 {
    -webkit-animation: steam1 4s ease-out 1s infinite;
    animation: steam1 4s ease-out 1s infinite;
  }

  @-webkit-keyframes steam1 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.2;
    }
    100% {
      transform: translateY(-200px) translateX(-20px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam1 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.2;
    }
    100% {
      transform: translateY(-200px) translateX(-20px) scale(1);
      opacity: 0;
    }
  }

  #steam2 {
    -webkit-animation: steam2 4s ease-out 0.5s infinite;
    animation: steam2 4s ease-out 0.5s infinite;
  }

  #steam4 {
    -webkit-animation: steam2 4s ease-out 1.5s infinite;
    animation: steam2 4s ease-out 1.5s infinite;
  }

  @-webkit-keyframes steam2 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.2;
    }
    100% {
      transform: translateY(-200px) translateX(20px) scale(1);
      opacity: 0;
    }
  }

  @keyframes steam2 {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.2;
    }
    100% {
      transform: translateY(-200px) translateX(20px) scale(1);
      opacity: 0;
    }
  }

  #cup {
    z-index: 1;
  }

  #cup-body {
    position: absolute;
    height: 200px;
    width: 300px;
    border-radius: 0 0 150px 150px;
    background-color: #e9e4d4;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
  }

  #cup-shade {
    position: relative;
    height: 300px;
    width: 200px;
    // background-color: lightseagreen;
    display: inline-block;
    margin-left: 42%;
    margin-top: -3px;
    transform: rotate(50deg);
    z-index: 1;
  }

  #cup-handle {
    position: relative;
    height: 75px;
    width: 80px;
    border-radius: 0 150px 150px 0;
    border: 15px solid #c7d1c4;
    margin-bottom: 95px;
    margin-left: 250px;
    display: inline-block;
    z-index: 0;
  }

  #saucer {
    position: absolute;
    height: 30px;
    width: 300px;
    border-radius: 0 0 100px 100px;
    background-color: #e9e4d4;
    margin-top: -32px;
    margin-left: 5px;
    z-index: 2;
  }

  #shadow {
    height: 10px;
    width: 300px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: 6px;
    // background-color: #e9e4d4;
  }

  .herbs-and-tea-title {
    height: 143px;
    // padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid black;
    background-color: #800317;
    opacity: 0.7;
    position: absolute;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px h2 {
      font-weight: bold;
      margin: 0 6px 0;
      font-size: 22px;
      color: #4a4a4a;
      text-align: center;
    }

    p {
      font-weight: lighter;
      font-size: 16px;
    }
  }
}
