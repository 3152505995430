.category-preview-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .title {
    font-size: 28px;
    margin-bottom: 25px;
    cursor: pointer;
    color: gray;
  }

  .preview {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
  }
}

/* Large screens (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .category-preview-container .title {
    font-size: 32px;
  }

  .category-preview-container .preview {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Medium screens (tablets, 768px to 1023px) */
@media (max-width: 1023px) {
  .category-preview-container .title {
    font-size: 28px;
  }

  .category-preview-container .preview {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small screens (phones, 767px and down) */
@media (max-width: 767px) {
  .category-preview-container .title {
    font-size: 24px;
  }

  .category-preview-container .preview {
    grid-template-columns: 1fr;
    column-gap: 10px;
  }
}
